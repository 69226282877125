import * as Api from '@/api/course/course'
import * as Util from '@/utils/utils'
import { formatMoment, translateTag, openLiveRoom} from '@/utils/utils'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      Util,
      activeName: '',
      courseDetailType: 1,
      privateSchedule: [],
    }
  },
  computed: {
    courseId () {
      return this.$route.params.id || ''
    },
      ...mapState({
          courseDesc: state => state.course.currentCourseDesc
      })
  },
  mounted () {
    this.getCourseSchedule()
  },
  methods: {
    getCourseSchedule () {
      Api.privateCourse('', (res) => {
        this.privateSchedule = res
      }, '', this.courseId)
    },

    handleClick (item) {
      switch (item.name) {
        case 'schedule':
          // Api.privateCourse(this.courseId, (res) => {
          //   this.classSchedule = res
          // })
          this.getCourseDetail()
          break
        case 'relation':
          Api.relationCourseApi(this.courseId, (res) => {
            this.relationCourse = res
          })
          break
        case 'comment':
          break
        case 'materials':
          Api.courseMaterials(this.courseId, (res) => {
            this.CourseMaterialData = res
          })
      }
    },
    formatMoment,
    translateTag,
    openLiveRoom,
    enterLive(item) {
      let _this = this
      openLiveRoom(`/private/live/${item.id}`,1,item.id,this.$route, _this)
    }
  }
}
