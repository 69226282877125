<template>
  <div class="class_page">
    <div class="course_overview" v-if="courseDesc">
      <div class="overview_con">
        <div class="overview_title">
          <template v-if="translateTag(courseDesc.category_id)!=undefined">
             <span class="category_tag"
             >{{translateTag(courseDesc.category_id).abbr}}</span>
            <span class="title">{{translateTag(courseDesc.category_id).name}}{{courseDesc.course_subject}}
</span>
          </template>

        </div>
        <div class="overview_desc" v-if="courseDesc.teacher">
          <div class="desc_teacher">{{courseDesc.teacher}}老师</div>
          <div class=""><span class="key">总课时</span> <span class="value">{{ courseDesc.total_period}}</span></div>
          <div class=""><span class="key">已完成课时/剩余课时</span> <span
              style="color:#29d087"> {{ courseDesc.finished_period}}</span><span class="value">/{{courseDesc.usable_period}}</span>
          </div>
          <div class=""><span class="key">有效期至</span><span class="value">{{formatMoment(courseDesc.expired_at, 'YYYY/MM/DD')}}</span>
          </div>
          <div class=""><span class="key">下次上课</span><span class="value">{{formatMoment(courseDesc.next_started_at, 'YYYY/MM/DD')}} {{formatMoment(courseDesc.next_started_at, 'HH:mm')}} - {{formatMoment(courseDesc.next_ended_at, 'HH:mm')}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="course_list">
      <div class="list_top">课程表</div>
      <div class="list_con">
        <div class="item" v-for="(item,index) in privateSchedule" :key="item.id">
          <div class="item_index">{{index+1}}</div>
          <div class="item_time">{{formatMoment(item.started_at,'YYYY/MM/DD')}}
            {{formatMoment(item.started_at,'HH:mm')}}
            - {{formatMoment(item.ended_at, 'HH:mm')}}
          </div>
          <div class="item_teacher">{{item.teacher}}</div>
          <div class="item_status item_status--green" v-if="item.status === 1">待上课</div>
          <div class="item_status" v-else-if="item.status === 2&&item.is_recorded">观看回放</div>
          <template v-else-if="item.status === 2&&!item.is_recorded">
            <div class="item_status" v-if="item.is_online_course">回放生成中</div>
            <div class="item_status" v-else>已完成</div>
          </template>
          <div class="item_status" v-else-if="item.status === 3">已取消</div>
          <div class="item_button button button--small button--green"
               @click="enterLive(item)"
               v-if="item.status === 1">进入直播
          </div>

          <div class="item_button button button--small button--green" v-else-if="item.status === 2&&item.is_recorded"
               @click="Util.openPage(`/course/private/replay/${item.id}`)">观看回放
          </div>
          <div class="item_button button button--small button--disable button--green"
               v-else-if="item.status === 2&&!item.is_recorded">观看回放
          </div>
          <div class="item_button button button--small  button--green" v-else-if="item.status === 3&&item.is_recorded"
               @click="Util.openPage(`/course/private/replay/${item.id}`)">
            观看回放
          </div>
          <div class="item_button button button--small button--disable button--green" v-else-if="item.status === 3">
            进入直播
          </div>
        </div>
      </div>
      <div class="usable_box" v-if="courseDesc">
        <div class="usable" v-if="courseDesc.period&&courseDesc.period.usable>0">您还有{{courseDesc.period.usable}}小时待预约~
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import LivePrivateDetailJS from './LivePrivateDetail.js'

  export default LivePrivateDetailJS
</script>

<style lang="less" scoped>
  .class_page {
    width: 100%;
  }

  .course_overview {
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto 20px;
    height: 116px;
    background: #fff;

    .overview_con {
      width: 1200px;
      padding: 25px 20px;
      box-sizing: border-box;
      margin: 0 auto;

    }

    .overview_title {
      display: flex;
      align-items: center;

      .type_tag {
        padding: 1px 4px;
        margin-right: 8px;
        background: #DEF7E6;
        color: #20A84C;
        font-size: @fontSizeCon;
      }

      .title {
        color: #333;
        font-size: 26px;
        font-weight: bold;
      }
    }

    .overview_desc {
      margin-top: 10px;
      display: flex;

      div {
        margin-right: 60px;
      }

      div:last-child {
        margin-right: 0;
      }

      .desc_teacher {
        color: #333;
      }

      .key {
        color: #999;
        margin-right: 1em;
      }

      .value {
        color: #555;
        font-weight: bold;
      }
    }
  }

  .course_list {
    box-sizing: border-box;
    padding: 0 20px 20px;
    width: 1200px;
    margin: 0 auto;
    background: #fff;
  }

  .list_top {
    width: 100%;
    height: 56px;
    line-height: 56px;
    border-bottom: 4px solid @papaGreen;
  }

  .list_con {

    .item {
      width: 100%;
      height: 58px;
      border-bottom: 1px solid #e6e6e6;
      color: #333;

      .item_index, .item_time, .item_teacher, .item_status {
        float: left;
        line-height: 58px;
        text-align: left;
      }

      .item_index {
        padding-left: 8px;
        width: 200px;
      }

      .item_time {
        width: 360px;
      }

      .item_teacher {
        width: 240px;
      }

      .item_status {
        width: 100px;
        color: #ccc;
      }

      .item_status--green {
        color: @papaGreen;
      }

      .item_button {
        margin-top: 11px;
        float: right;
        width: 90px;
        height: 36px;
        line-height: 36px;
      }
    }
  }

  .usable_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .usable {
    margin: 30px 0;
    width: 180px;
    height: 36px;
    background: #eeeeee;
    border-radius: 18px;
    text-align: center;
    line-height: 36px;
    color: #999;
    font-size: 12px;
  }
</style>
